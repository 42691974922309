import React, { useMemo } from "react";
import styled from "styled-components";

import { LAPTOP_MOBILE_POINTER } from "@tools/constants";
import { TypographyUI } from "@ui";

import { DATA } from "./constants";
import { useGetSectionThreeImgData } from "./hooks";
import { getImage, StaticImage } from "gatsby-plugin-image";
import { BannerCt, ChessGridCt } from "@components";

export const SectionFiveTt = () => {
  const imgsData = useGetSectionThreeImgData();
  const contentList = useMemo(
    () =>
      DATA.map(({ subtitle, title, infoText, nameImg, link }) => ({
        imgData: getImage(filterOfImgData(nameImg, imgsData).node),
        subtitle,
        title,
        infoText,
        link,
      })),
    [imgsData]
  );

  return (
    <>
      <SectionSd>
        <TextBoxSd>
          <TypographyUI.H2 swichPoint={LAPTOP_MOBILE_POINTER} $mb="12px" $tac>
            Дополнительные возможности сервиса
          </TypographyUI.H2>
        </TextBoxSd>

        <ChessGridCt contentList={contentList.slice(0, 2)} reverse />
      </SectionSd>
      <SectionSd>
        <TextBoxSd>
          <TypographyUI.H2 swichPoint={LAPTOP_MOBILE_POINTER} $mb="12px" $tac>
          Продукты для самозанятых
          </TypographyUI.H2>
        </TextBoxSd>

        <ChessGridCt contentList={contentList.slice(2, 4)} reverse />
      </SectionSd>
    </>
  );
};

const filterOfImgData = (name, data) =>
  data.allFile.edges.filter((i) => i?.node?.name === name)[0];

const SectionSd = styled.section`
  display: flex;
  flex-direction: column;
  transition: all 0.3s;
  flex-direction: column;
  align-items: center;
  margin-bottom: 160px;
`;

const TextBoxSd = styled.div`
  max-width: 820px;
  width: 100%;
  @media (min-width: ${LAPTOP_MOBILE_POINTER + 1}px) {
    margin-bottom: 70px;
  }
  @media (max-width: ${LAPTOP_MOBILE_POINTER}px) {
    margin-bottom: 40px;
  }
`;
