import {
  GatsbyImage,
  IGatsbyImageData,
  StaticImage,
} from "gatsby-plugin-image";
import React from "react";
import styled from "styled-components";

import { useSlidesControl } from "@tools/hooks";

const TIME = 8000;

type TProps = {
  imgDataList: IGatsbyImageData[];
};
export const ImgSlideCt: React.FC<TProps> = ({ imgDataList }) => {
  const [activeIndex] = useSlidesControl(imgDataList.length, TIME);
  return (
    <BoxSd>
      <BgdBoxSd>
        <StaticImage
          src="../../../../images/main/sectionFirst/png/main-bgd.png"
          alt="самозанятый"
          loading="eager"
          placeholder="none"
          formats={["auto", "webp"]}
          layout="fixed"
          width={535}
          height={505}
        />
      </BgdBoxSd>
      {imgDataList?.map((img, id) => (
        <ImgBoxSd key={`${id}`} $visible={activeIndex == id}>
          <GatsbyImage image={img} alt={`img-slide-${activeIndex}`} />
        </ImgBoxSd>
      ))}
    </BoxSd>
  );
};

const BoxSd = styled.div`
  position: relative;
`;

const ImgBoxSd = styled.div<{ $visible: boolean }>`
  position: absolute;
  top: 0;
  left: 77px;
  opacity: ${({ $visible }) => ($visible ? 1 : 0)};
  transition: opacity .5s ease-in-out;
  max-width: 100%;
  width: 440px;
  height: 491px;
`;

const BgdBoxSd = styled.div`
  padding-top: 56px;
  width: 535px;
  height: 505px;
  & > * {
    width: 436px;
    height: 416px;
  }
`;
