type TData = {
  subtitle?: string;
  title?: string;
  infoText?: string;
  nameImg?: string;
  link?: string;
}[];

export const DATA: TData = [
  {
    subtitle: 'Проверка статуса по ИНН',
    title: "Как мы проверяем исполнителей",
    infoText:
      "При регистрации пользователи указывают ИНН — так мы проверяем исполнителей на наличие статуса самозанятого.\nЕсли самозанятый теряет статус, то он больше не может выполнять задания",
    nameImg: "picture0",
    link: '/check-inn'
  },
  {
    subtitle: 'Выплаты самозанятым',
    title: "Автоматизируем работу  компаний с самозанятыми",
    infoText:
      "Поможем организациям и индивидуальным предпринимателям настроить массовые выплаты самозанятым без риска блокировки расчетного счета",
    nameImg: "picture1",
    link: '/business',
  },
  {
    subtitle: 'Сам.Кошелек',
    title: "Электронный кошелек со множеством функций",
    infoText:
      "На наш кошелек можно не только принимать деньги, но и создавать с его помощью уникальные ссылки для оплаты товаров и услуг⁤",
    nameImg: "picture2",
    link: '/wallet',
  },
  {
    subtitle: 'Эквайринг',
    title: "Интернет-эквайринг",
    infoText:
      "Настройте оплату товаров и автоматическую отправку чеков на вашем сайте без обращения в банк",
    nameImg: "picture3",
    link: '/acquiring',
  }
];
