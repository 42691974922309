import { graphql, useStaticQuery } from "gatsby";

export const useGetImgDataToFirstSection =  () => useStaticQuery(graphql`
  {
    allFile(
      filter: {
        relativeDirectory: { eq: "images/main/sectionFirst/png" }
      }
    ) {
      edges {
        node {
          id
          name
          childImageSharp {
            gatsbyImageData(
              placeholder: NONE
              formats: [AUTO, WEBP, AVIF]
              width: 440
              height: 491
            )
          }
        }
      }
    }
  }
`);
