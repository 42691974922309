import React from "react";
import { Helmet } from "react-helmet";

import {
  SectionFirstTt,
  SectionTwoTt,
  SectionThreeTt,
  SectionFourTt,
  SectionFiveTt,
  SectionSixTt,
  SectionSevenTt,
  SectionTenTt,
} from "@templates/main";

import { Layout } from "@components";

const IndexPage = () => (
  <>
    <Helmet>
      <meta charSet="utf-8" />
      <title>{`Сайт Самозанятые РФ ${new Date().getFullYear()} - официальная платформа для самозанятых граждан РФ и работы с ними | Портал и площадка с отзывами`}</title>
      <meta name="description" content="Ищите официальную платформу для работы с самозанятыми гражданами РФ? Наш сайт поможет вам с этим. Официальные выплаты самозанятым. Автоматизация документооборота" />
      <link rel="canonical" href="https://xn--80aapgyievp4gwb.xn--p1ai" />
      <link rel="icon" type="image/svg+xml" href="/favicon.svg" sizes="any" />
      <link rel="apple-touch-icon" href="/apple-touch-icon.png" type="image/png" />
    </Helmet>
    <Layout>
      <SectionFirstTt />
      <SectionTwoTt />
      <SectionThreeTt />
      <SectionFourTt />
      <SectionFiveTt />
      <SectionSixTt />
      <SectionSevenTt />
      <SectionTenTt />
    </Layout>
  </>
);

export default IndexPage;
