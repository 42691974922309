import React from "react";
import styled from "styled-components";
import SwiperCore, { Autoplay, Pagination, Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import { LAPTOP_MOBILE_POINTER } from "@tools/constants";
import { LineBreakUI, TypographyUI } from "@ui";

import srcStarSvg from "@images/main/sectionFour/star.svg";
import srcArrowRightSvg from "@images/main/sectionFour/arrow-right.svg";
import srcRemontSvg from "@images/main/sectionFour/roundIcons/Remont.svg";
import srcPhoneSvg from "@images/main/sectionFour/roundIcons/Phone.svg";
import srcCleanSvg from "@images/main/sectionFour/roundIcons/Clean.svg";
import srcCompSvg from "@images/main/sectionFour/roundIcons/Comp.svg";
import srcDeliverySvg from "@images/main/sectionFour/roundIcons/Delivery.svg";
import { useWindowSize } from "@tools/hooks";

const CONTENT_DATA = [
  {
    title: "Замена смесителя",
    icon: srcRemontSvg,
    rating: 5,
    text:
      "Дома сломался смеситель. Я лично с такими задачами раньше не сталкивалась и решила обратиться к специалисту. Чтобы наверняка! \nСам.Маркет сразу предложил больше 10 сантехников недалеко от меня. Договорилась о выполнении услуги и в тот же день смеситель был на месте 😊 \nПять баллов сервису за удобство!",
    author: "Анастасия Д.",
    data: "22.12.2021",
  },
  {
    title: "Настройка таргета для Инстаграма",
    icon: srcPhoneSvg,
    rating: 5,
    text:
      "Нашей компании срочно понадобились услуги тареголога из-за внезапного увольнения маркетолога. Долго думали, где искать замену, решили попробовать Маркет. \nПодкупило то, что точно получим от специалиста чек и другие документы. Ну и как-то доверия больше. Сервис понравился, потому что все официально.",
    author: "Константин В.",
    data: "17.09.2019",
  },
  {
    title: "Генеральная уборка ",
    icon: srcCleanSvg,
    rating: 5,
    text:
      "Привет, Сам.Маркет! Я к вам с отличным отзывом =) \nДавно искала себе домработницу для ежемесячной генеральной уборки. Через приложение нашла Нину. У Нины золотые руки, теперь чистоту квартиры доверяю только ей. Буду рекомендовать вас всем!",
    author: "Таня К.",
    data: "3.06.2020",
  },

  {
    title: "Ремонт холодильника",
    icon: srcRemontSvg,
    rating: 5,
    text:
      "Дома сломался смеситель. Я лично с такими задачами раньше не сталкивалась и решила обратиться к специалисту. Чтобы наверняка! \nСам.Маркет сразу предложил больше 10 сантехников недалеко от меня. Договорилась о выполнении услуги и в тот же день смеситель был на месте 😊 \nПять баллов сервису за удобство!",
    author: "Анастасия Д.",
    data: "22.12.2021",
  },
  {
    title: "SEO-анализ сайта ",
    icon: srcCompSvg,
    rating: 5,
    text:
      "У меня небольшой свечной бизнес, делаю все сама, без сотрудников. \nУслышала, что можно увеличить продажи с помощью грамотной сео стратегии. Самой погружаться в процесс не хотелось. Решила выделить небольшой бюджет и отдать специалисту в работу. Сначала не понимала, что делает маркетолог, но потом сработались. Результат правда есть!",
    author: "Диана П.",
    data: "17.09.2019",
  },
  {
    title: "Доставка бумаг",
    icon: srcDeliverySvg,
    rating: 5,
    text:
      "Ставлю пять звезд, хоть курьер и опоздал. Но пробки, бывает с каждым. Опоздание компенсируют вежливость и профессионализм. \nДумаю, обращусь еще раз. Спасибо!",
    author: "Антон В.",
    data: "15.08.2021",
  },
];

export const FeedbackCt = () => {
  SwiperCore.use([Autoplay, Pagination, Navigation]);
  const { width } = useWindowSize();
  const pagination = {
    clickable: true,
    renderBullet: (id, className) =>
      `<div class="custome-pagination ${className}"></div>`,
  };

  const swiperProps = {
    spaceBetween: 30,
    initialSlide: 1,
    slidesPerView: width > LAPTOP_MOBILE_POINTER ? 3 : 1,
    loop: true,
    autoplay: {
      delay: 8000,
      disableOnInteraction: true,
    },
    pagination,
    navigation: {
      nextEl: ".next",
      prevEl: ".prev",
    },
  };

  return (
    <BoxSd>
      <SwiperSd {...swiperProps}>
        {CONTENT_DATA.map(({ title, icon, rating, text, author, data }, id) => (
          <SwiperSlide key={`${title}`} virtualIndex={id}>
            <ItemBoxSd>
              <TypographyUI.H4Bold $ga="title">{title}</TypographyUI.H4Bold>
              <IconBoxSd>
                <img src={icon} alt="icon" />
              </IconBoxSd>
              <StarsBoxSd>
                {Array(rating)
                  .fill("")
                  .map((i,id) => (
                    <img key={`${title}_${id}`} src={srcStarSvg} alt="star" />
                  ))}
                {rating === 5 && (
                  <TypographyUI.Body2Regular $c="var(--text-disabled)">
                    Великолепно
                  </TypographyUI.Body2Regular>
                )}
              </StarsBoxSd>
              <TypographyUI.Body2Regular $ga="text">
                <LineBreakUI>{text}</LineBreakUI>
              </TypographyUI.Body2Regular>
              <TypographyUI.Body2Regular
                $ga="author"
                $c="var(--text-placeholder)"
              >
                {author}
              </TypographyUI.Body2Regular>
              <TypographyUI.Body2Regular
                $ga="data"
                $c="var(--text-placeholder)"
                $tae
              >
                {data}
              </TypographyUI.Body2Regular>
            </ItemBoxSd>
          </SwiperSlide>
        ))}
      </SwiperSd>
      <CustomNavBoxSd>
        <CustomNavButtonSliderSd className="prev">
          <img
            src={srcArrowRightSvg}
            alt="left"
            style={{ transform: "rotate(180deg)" }}
          />
        </CustomNavButtonSliderSd>
        <CustomNavButtonSliderSd className="next">
          <img src={srcArrowRightSvg} alt="right" />
        </CustomNavButtonSliderSd>
      </CustomNavBoxSd>
    </BoxSd>
  );
};

const CustomNavBoxSd = styled.div`
  position: absolute;
  top: 0%;
  width: calc(100% + 46px);
  height: 100%;
  margin-left: -23px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: ${LAPTOP_MOBILE_POINTER}px) {
    display: none;
  }
`;

const CustomNavButtonSliderSd = styled.div`
  height: 48px;
  width: 48px;
  background: #ffffff;
  box-shadow: 0px 0px 12px rgba(97, 106, 136, 0.1);
  border-radius: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 1;
`;

const BoxSd = styled.div`
  transition: 0.3s;
  width: 100%;
  height: 100%;
  position: relative;
  ${CustomNavBoxSd} {
    transition: opacity 0.1s linear;
    opacity: 0;
  }
  &:hover > ${CustomNavBoxSd} {
    opacity: 1;
  }
`;

const SwiperSd = styled(Swiper)`
  .swiper-slide {
    @media (max-width: ${LAPTOP_MOBILE_POINTER}px) {
      margin-bottom: 52px;
    }
  }
  .swiper-pagination {
    padding-top: 24px;
    @media (min-width: ${LAPTOP_MOBILE_POINTER + 1}px) {
      display: none;
    }
    .custome-pagination {
      background: #b0b5c4;
      border-radius: 5px;
      height: 3px;
      width: 22px;
      & + & {
        margin-left: 20px;
      }
    }
  }
`;

const ItemBoxSd = styled.div`
  display: grid;
  grid-template-areas:
    "title icon"
    "rating rating"
    "text text"
    "author data";
  grid-template-rows: 48px 36px 192px 24px;
  @media (min-width: ${LAPTOP_MOBILE_POINTER + 1}) {
    grid-template-columns: 285px 75px;
  }
  @media (max-width: ${LAPTOP_MOBILE_POINTER}) {
    grid-template-columns: 265px 75px;
  }
`;

const IconBoxSd = styled.div`
  grid-area: "icon";
  margin-left: auto;
  margin-right: 0;
`;

const StarsBoxSd = styled.div`
  grid-area: "rating";
  display: flex;
  align-items: center;
  & > * + * {
    margin-left: 4px;
  }
`;
