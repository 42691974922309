import React from "react";
import styled from "styled-components";

import { LAPTOP_MOBILE_POINTER } from "@tools/constants";
import { TypographyUI } from "@ui";
import { FeedbackCt } from "./components";

export const SectionFourTt = () => (
  <SectionBoxSd>
    <TextBoxSd>
      <TypographyUI.H2 swichPoint={LAPTOP_MOBILE_POINTER} $tac $mb="12px">
        Отзывы
      </TypographyUI.H2>
      <TypographyUI.Body1Regular
        swichPoint={LAPTOP_MOBILE_POINTER}
        $tac
        $c="var(--text-secondary)"
      >
        <span>203 496 отзывов оставили клиенты за время существования сервиса.</span>
        <br />
        <span>Из них 193 355 — положительные</span>
      </TypographyUI.Body1Regular>
    </TextBoxSd>
    <FeedbackCt />
  </SectionBoxSd>
);

const SectionBoxSd = styled.section`
  display: flex;
  flex-direction: column;
  transition: all 0.3s;
  flex-direction: column;
  align-items: center;
  margin-bottom: 160px;
  padding-top: 120px;
  @media (max-width: ${LAPTOP_MOBILE_POINTER}px) {
    padding-top: 80px;
  }
`;

const TextBoxSd = styled.div`
  max-width: 820px;
  width: 100%;
  @media (min-width: ${LAPTOP_MOBILE_POINTER + 1}px) {
    margin-bottom: 80px;
  }
  @media (max-width: ${LAPTOP_MOBILE_POINTER}px) {
    margin-bottom: 40px;
  }
`;
