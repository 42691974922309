import React, { useMemo } from "react";
import styled from "styled-components";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import { LAPTOP_MOBILE_POINTER } from "@tools/constants";
import { LinkUI, TypographyUI } from "@ui";

import { useGetImgData } from "./hooks";

const CONTENT_DATA = [
  {
    iconName: "Delivery",
    title: "Курьерские услуги",
    link: "https://market.selfwork.ru/",
    sum: "от 300 ₽",
  },
  {
    iconName: "Cleaning",
    title: "Генеральная уборка",
    link: "https://market.selfwork.ru/",
    sum: "от 1 000 ₽",
  },
  {
    iconName: "Texting",
    title: "Услуги копирайтера ",
    link: "https://market.selfwork.ru/",
    sum: "от 400 ₽",
  },
  {
    iconName: "Repair",
    title: "Ремонт бытовой техники ",
    link: "https://market.selfwork.ru/",
    sum: "от 500 ₽",
  },
];

const filterOfImgData = (name, data) =>
  data.allFile.edges.filter((i) => i?.node?.name === name)[0];

export const SectionTwoTt = () => {
  const imgsData = useGetImgData();
  const renderData = CONTENT_DATA.map((item) => ({
    ...item,
    iconData: getImage(filterOfImgData(item.iconName, imgsData).node),
  }));

  return (
    <SectionBoxSd id="twoSection">
      <TextBoxSd>
        <TypographyUI.H2 swichPoint={LAPTOP_MOBILE_POINTER}>
          Популярные услуги на платформе
        </TypographyUI.H2>
      </TextBoxSd>
      <CardsBoxSd>
        {renderData.map(({ iconData, title, sum, link }, id) => (
          <CardSd key={`${title}_${id}`} href={link}>
            <GatsbyImage image={iconData} alt={title} />
            <TypographyUI.Body1Medium $w="100%" $di>
              {title}
            </TypographyUI.Body1Medium>
            <TypographyUI.Body2Regular $w="100%" $di>
              {sum}
            </TypographyUI.Body2Regular>
          </CardSd>
        ))}
      </CardsBoxSd>
    </SectionBoxSd>
  );
};

const SectionBoxSd = styled.section`
  display: flex;
  flex-direction: column;
  transition: all 0.3s;
  align-items: start;
  margin-bottom: 160px;
  padding-top: 120px;
  @media (max-width: ${LAPTOP_MOBILE_POINTER}px) {
    padding-top: 80px;
  }
`;

const TextBoxSd = styled.div`
  max-width: 820px;
  width: 100%;
  @media (min-width: ${LAPTOP_MOBILE_POINTER + 1}px) {
    margin-bottom: 48px;
  }
  @media (max-width: ${LAPTOP_MOBILE_POINTER}px) {
    margin-bottom: 40px;
    text-align: center;
  }
`;

const CardsBoxSd = styled.div`
  transition: 1s;
  @media (min-width: ${LAPTOP_MOBILE_POINTER}px) {
    display: flex;
    & > * + * {
      margin-left: 32px;
    }
  }
  @media (max-width: ${LAPTOP_MOBILE_POINTER}px) {
    width: 100%;
    & > * + * {
      margin-top: 16px;
    }
  }
`;

const CardSd = styled(LinkUI.NewWindow)`
  transition: 0.3s;
  background: var(--background-main-white);
  box-shadow: 0px 0px 20px rgba(97, 106, 136, 0.12);
  border-radius: 16px;
  padding: 24px;
  height: 196px;

  display: flex;
  flex-direction: column;
  align-items: start;

  & > *:nth-child(1) {
    margin-bottom: 40px;
  }
  & > *:nth-child(2) {
    margin-bottom: 4px;
  }
  @media (max-width: ${LAPTOP_MOBILE_POINTER}px) {
  }
  @media (min-width: ${LAPTOP_MOBILE_POINTER + 1}px) {
    width: 262px;
  }
`;
