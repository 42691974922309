type TData = {
  subtitle?: string;
  title?: string;
  infoText?: string;
  nameImg?: string;
  link?: string;
}[];
export const DATA: TData = [
  {
    title: "Найдем идеального исполнителя",
    infoText:
      "В Сам.Маркете мы отобрали лучших специалистов из каждой области для выполнения задач организаций, ИП    и обычных физических лиц",
    nameImg: "picture-1",
    link: '/find-selfworkers'
  },
  {
    title: "Поможем самозанятым найти первых клиентов",
    infoText:
      "Только начали свое дело и не знаете, как собрать клиентскую базу? Оформите самозанятость, зарегистрируйтесь в Сам.Маркете и начните получать первые заказы⁤",
    nameImg: "picture-2",
    link: '/selfworkers',
  }
];
