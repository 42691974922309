import React, { useMemo } from "react";
import styled from "styled-components";

import { LAPTOP_MOBILE_POINTER } from "@tools/constants";
import { LinkUI, TypographyUI } from "@ui";

import { StaticImage } from "gatsby-plugin-image";

export const SectionSevenTt = () => {
  return (
    <SectionSd>
      <BodyBoxSd>
        <ImagesBoxSd>
          <StaticImage
            src="../../images/main/sectionSeven/picture.png"
            alt="самозанятые"
            loading="eager"
            placeholder="none"
            formats={["auto", "webp"]}
            width={490}
            height={350}
          />
        </ImagesBoxSd>
        <ContentBoxSd>
          <TypographyUI.H2 swichPoint={LAPTOP_MOBILE_POINTER} $mb="16px">
            В приложении удобнее
          </TypographyUI.H2>
          <InfoTetxtSd>
            Скачайте Сам.Маркет и начните сотрудничество с самозанятыми
          </InfoTetxtSd>
          <DownloadAppsSd>
            <LinkSd href={"https://apps.apple.com/app/id1488581137"}>
              <StaticImage
                src="../../images/shared/app-store.png"
                loading="eager"
                placeholder="none"
                formats={["auto", "webp"]}
                width={152}
                height={48}
                alt="android-app"
              />
            </LinkSd>
            <LinkSd
              href={
                "https://play.google.com/store/apps/details?id=ru.selfwork.marketplace"
              }
            >
              <StaticImage
                src="../../images/shared/google-store.png"
                loading="eager"
                placeholder="none"
                formats={["auto", "webp"]}
                width={152}
                height={48}
                alt="ios-app"
              />
            </LinkSd>
          </DownloadAppsSd>
        </ContentBoxSd>
      </BodyBoxSd>
    </SectionSd>
  );
};

const SectionSd = styled.section`
  display: flex;
  flex-direction: column;
  transition: all 0.3s;
  flex-direction: column;
  align-items: center;
`;

const ImagesBoxSd = styled.div`
  position: relative;
  @media (min-width: ${LAPTOP_MOBILE_POINTER + 1}px) {
    width: 490px;
    height: 350px;
  }
  @media (max-width: ${LAPTOP_MOBILE_POINTER}px) {
   display: none;
  }
`;

const BodyBoxSd = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  @media (max-width: ${LAPTOP_MOBILE_POINTER}px) {
    flex-direction: column;
  }
`;

const ContentBoxSd = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 480px;
  @media (min-width: ${LAPTOP_MOBILE_POINTER}px) {
    justify-content: center;
  }
`;

const InfoTetxtSd = styled(TypographyUI.Body1Regular)`
  & + & {
    margin-top: 20px;
    margin-bottom: 40px;
  }
  color: var(--text-secondary);
  @media (min-width: ${LAPTOP_MOBILE_POINTER + 1}px) {
    width: 480px;
  }
  @media (max-width: ${LAPTOP_MOBILE_POINTER + 1}px) {
    width: 100%;
  }
`;

const DownloadAppsSd = styled.div`
  margin-top: 48px;
  display: flex;
  @media (min-width: ${LAPTOP_MOBILE_POINTER + 1}px) {
    & > * + * {
      margin-left: 16px;
    }
  }
  @media (max-width: ${LAPTOP_MOBILE_POINTER}px) {
    & > * + * {
      margin-left: 8px;
    }
  }
`;

const LinkSd = styled(LinkUI.NewWindow)`
  display: block;
  width: fit-content;
`;
