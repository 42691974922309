import React, { useMemo } from "react";
import styled from "styled-components";

import {  StaticImage } from "gatsby-plugin-image";
import { BannerCt } from "@components";

export const SectionSixTt = () => (
  <SectionSd>
    <BannerCt
      title="Исполнители рядом"
      info="Мы найдём специалистов, которые живут рядом с вами. Введите специализацию, например, дизайнер или курьер"
      buttonLink="https://market.selfwork.ru/"
      buttonText="Найти исполнителя"
      imgNode={
        <StaticImage
          src="../../images/main/sectionFive/SmallBannerImage.png"
          alt="Настроить оплату на сайте"
          loading="eager"
          placeholder="none"
          formats={["auto", "webp"]}
          width={552}
          height={444}
          layout="fixed"
          objectFit="contain"
        />
      }
    />
  </SectionSd>
);

const SectionSd = styled.section`
  display: flex;
  flex-direction: column;
  transition: all 0.3s;
  flex-direction: column;
  align-items: center;
  margin-bottom: 120px;
`;
