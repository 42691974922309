import { getImage, StaticImage } from "gatsby-plugin-image";
import React, { useMemo, useState } from "react";
import styled from "styled-components";

import { LAPTOP_MOBILE_POINTER } from "@tools/constants";
import { TypographyUI, ButtonUI, InputUI, LinkUI } from "@ui";
import srcSearchIconSvg from "@images/main/sectionFirst/search-icon.svg";
import { useWindowSize } from "@tools/hooks";
import { ImgSlideCt } from "./components";
import { useGetImgDataToFirstSection } from "./hooks";
import { useSendEvent } from "@tools/hooks";

const imgName = ["firstImg", "twoImg", "threeImg"];

export const SectionFirstTt: React.FC = () => {
  const { width } = useWindowSize();
  const { clickFindPerformer } = useSendEvent();

  const [value, setValue] = useState("");

  const imgsData = useGetImgDataToFirstSection();
  const imgsDataNormalized = useMemo(
    () =>
      imgName.map((itemName) =>
        getImage(filterOfImgData(itemName, imgsData).node)
      ),
    [imgsData]
  );

  const handlerForm = (e) => {
    e.preventDefault();
    clickFindPerformer();
    window.open("https://market.selfwork.ru");
  };

  return (
    <SectionBoxSd>
      <ContentBoxSd>
        <TextOfTitleSd swichPoint={LAPTOP_MOBILE_POINTER}>
          Найдите надежного исполнителя
        </TextOfTitleSd>
        <TextOfInfo swichPoint={LAPTOP_MOBILE_POINTER}>
          Сам.Маркет — место, где работают самозанятые исполнители из разных
          областей. Найдите идеального подрядчика в один клик
        </TextOfInfo>
        <FormSd onSubmit={handlerForm}>
          <InputUI
            value={value}
            onChange={setValue}
            placeholder="Например, сантехник или курьер"
          />
          <ButtonSd
            icon={<img src={srcSearchIconSvg} alt="ic" />}
            type="submit"
          >
            {width <= LAPTOP_MOBILE_POINTER ? "Найти" : null}
          </ButtonSd>
        </FormSd>
        <TypographyUI.Button
          $mt="28px"
          $ml="16px"
          $tac={width <= LAPTOP_MOBILE_POINTER}
        >
          <LinkUI.NewWindow theme="blue" href="https://market.selfwork.ru">
            Я хочу стать исполнителем
          </LinkUI.NewWindow>
        </TypographyUI.Button>
      </ContentBoxSd>

      <ImagesBoxSd>
        {width > LAPTOP_MOBILE_POINTER && imgsDataNormalized && (
          <ImgSlideCt imgDataList={imgsDataNormalized} />
        )}
        {width > LAPTOP_MOBILE_POINTER && (
          <BgDsBoxSd>
            <StaticImage
              src="../../../images/shared/gradiBgDsLight.png"
              loading="eager"
              placeholder="none"
              formats={["auto", "webp"]}
              width={905}
              height={720}
              layout="fixed"
              objectFit="contain"
              alt="эквайринг"
            />
          </BgDsBoxSd>
        )}
      </ImagesBoxSd>
      {width <= LAPTOP_MOBILE_POINTER && (
        <BgMbBoxSd>
          <StaticImage
            src="../../../images/shared/gradiBgMb.png"
            loading="eager"
            placeholder="none"
            formats={["auto", "webp"]}
            width={572}
            height={573}
            objectFit="contain"
            alt="эквайринг"
          />
        </BgMbBoxSd>
      )}
    </SectionBoxSd>
  );
};

const filterOfImgData = (name, data) =>
  data.allFile.edges.filter((i) => i?.node?.name === name)[0];

const SectionBoxSd = styled.section`
  transition: 0.3s;
  width: 100%;
  @media (min-width: ${LAPTOP_MOBILE_POINTER + 1}px) {
    display: grid;
    grid-template-columns: 500px 530px;
    column-gap: 128px;
    align-items: center;
  }
  @media (max-width: ${LAPTOP_MOBILE_POINTER}px) {
    padding-top: 40px;
  }
`;

const ContentBoxSd = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  @media (min-width: ${LAPTOP_MOBILE_POINTER + 1}px) {
    margin-top: 110px;
  }
`;

const TextOfTitleSd = styled(TypographyUI.H1)`
  padding-bottom: 14px;
  @media (max-width: ${LAPTOP_MOBILE_POINTER}px) {
    padding-bottom: 17px;
  }
`;

const TextOfInfo = styled(TypographyUI.Body1Regular)`
  color: var(--text-secondary);
`;

const FormSd = styled.form`
  margin-top: 54px;
  display: flex;
  align-items: center;
  @media (max-width: ${LAPTOP_MOBILE_POINTER}px) {
    flex-direction: column;
    & > * + * {
      margin-top: 16px;
    }
  }
`;

const ButtonSd = styled(ButtonUI)`
  @media (min-width: ${LAPTOP_MOBILE_POINTER + 1}px) {
    margin-left: 16px;
    width: auto;
  }
  @media (max-width: ${LAPTOP_MOBILE_POINTER}px) {
    width: 100%;
  }
`;

const ImagesBoxSd = styled.div`
  position: relative;
  @media (min-width: ${LAPTOP_MOBILE_POINTER + 1}px) {
    min-width: 505px;
    min-height: 505px;
  }
`;

const BgDsBoxSd = styled.div`
  z-index: -1;
  position: absolute;
  top: 0;
  left: -190px;
  height: 720px;
  width: 905px;
  & > * {
    height: 100%;
    width: 100%;
  }
`;

const BgMbBoxSd = styled.div`
  z-index: -3;
  position: absolute;
  top: 82px;
  left: 50%;
  margin-left: calc(-50% + 10px + 10px);
  width: calc(100% - 20px - 20px);
  display: flex;
  justify-content: center;
  & > * {
    height: 100%;
    width: 100%;
    max-width: 570px;
  }
`;
