import React from "react";
import styled from "styled-components";

import { LAPTOP_MOBILE_POINTER } from "@tools/constants";
import { FontMixinUI, LinkUI, TypographyUI } from "@ui";
import srcArrowRightSvg from "@images/main/sectionTen/arrow-right.svg";
import srcArrowRight1Svg from "@images/main/sectionTen/arrow-right-1.svg";

const urlKb = 'https://самозанятые.рф/blog/kb';
const LINKS_DATA = [
  {
    text: "Какие виды деятельности разрешены самозанятым?",
    href: `${urlKb}/npd/kakie-vidy-deyatelnosti-razresheny-sa`,
    iconSrc: srcArrowRightSvg,
  },
  {
    text: "Иностранцы могут стать самозанятыми?",
    href: `${urlKb}/npd/mogut-li-inostranczy-byt-samozanyatym`,
    iconSrc: srcArrowRightSvg,
  },
  {
    text: "Где действует новый налоговый режим?",
    href: `${urlKb}/npd/v-kakih-regionah-rossii-provoditsya-ek`,
    iconSrc: srcArrowRightSvg,
  },
  {
    text: "Кто считается самозанятым?",
    href: `${urlKb}/npd/kto-mozhet-primenyat-speczialnyj-nalo`,
    iconSrc: srcArrowRightSvg,
  },
  {
    text: "Чем могут заниматься самозанятые?",
    href: `${urlKb}/npd/kakie-vidy-deyatelnosti-razresheny-sa`,
    iconSrc: srcArrowRightSvg,
  },
  {
    text: "Фирмам нужно платить налог за самозанятых?",
    href: `${urlKb}/npd/chto-takoe-samozanyatost-ili-nalog-na-p`,
    iconSrc: srcArrowRightSvg,
  },
  {
    text: "Смотреть все вопросы",
    href: `${urlKb}`,
    iconSrc: srcArrowRight1Svg,
  },
];

export const SectionTenTt = () => (
  <SectionBoxSd>
    <TextBoxSd>
      <TypographyUI.H2 swichPoint={LAPTOP_MOBILE_POINTER} $tac $mb="48px">
        Частые вопросы
      </TypographyUI.H2>
      <LinksSd>
        {LINKS_DATA.map(({ text, href, iconSrc }, id) => (
          <LinkSd href={href} key={`${text}_${id}`}>
            <TextSd>{text}</TextSd>
            <CircleOfArrowSd>
              <img src={iconSrc} alt="->" />
            </CircleOfArrowSd>
          </LinkSd>
        ))}
      </LinksSd>
    </TextBoxSd>
  </SectionBoxSd>
);

const SectionBoxSd = styled.section`
  display: flex;
  transition: all 0.3s;
  flex-direction: column;
  align-items: center;
  margin-bottom: 120px;
  padding-top: 120px;
  @media (max-width: ${LAPTOP_MOBILE_POINTER}px) {
    padding-top: 80px;
  }
`;

const TextBoxSd = styled.div`
  width: 100%;
  @media (max-width: ${LAPTOP_MOBILE_POINTER}px) {
    margin-bottom: 40px;
  }
`;

const LinkSd = styled(LinkUI.NewWindow)`
  display: flex;
  justify-content: space-between;

  background: var(--background-main-white);
  box-shadow: 0px 0px 20px rgba(97, 106, 136, 0.12);
  border-radius: 16px;
  padding: 24px;
  width: 358px;
  @media (min-width: ${LAPTOP_MOBILE_POINTER + 1}px) {
    height: 112px;
  }
`;

const TextSd = styled(TypographyUI)`
  ${FontMixinUI.CirceNormal}
  font-size: 22px;
  line-height: 32px;
  color: inherit;
  @media (min-width: ${LAPTOP_MOBILE_POINTER + 1}px) {
    width: 256px;
  }
`;

const CircleOfArrowSd = styled.div`
  height: 32px;
  min-width: 32px;
  display: flex;
  justify-content: center;
  align-items: center;

  background: var(--background-default);
  border-radius: 24px;
`;

const LinksSd = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  & > * {
    color: var(--text-primary);
    &:last-of-type {
      color: var(--accent-main);
      height: 80px;
      ${CircleOfArrowSd} {
        background: var(--accent-main);
      }
    }
    @media (min-width: ${LAPTOP_MOBILE_POINTER + 1}px) {
      margin: 16px;
      &:nth-child(1) {
        margin-top: 0;
        margin-left: 0;
      }
      &:nth-child(2) {
        margin-top: 0;
      }
      &:nth-child(3) {
        margin-top: 0;
        margin-right: 0;
      }
      &:nth-child(4) {
        margin-left: 0;
      }
      &:nth-child(6) {
        margin-right: 0;
      }
      &:nth-child(7) {
        margin-bottom: 0;
      }
    }
    @media (max-width: ${LAPTOP_MOBILE_POINTER}px) {
      width: 100%;
      & + * {
        margin-top: 16px;
      }
    }
  }
`;
